import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import React from "react"
import { graphql } from "gatsby"
import { ProductItemCard } from "../components/ProductItemCard"
import { Layout } from "../components/layout"
import { ProductItemCardFragment } from "../components/__generated__/ProductItemCardFragment"
import InfiniteScroll from "react-infinite-scroller"
import { TagFilter, HidableFilters } from "../components/TagFilter"
import { withSearch, WithSearchWrappedProps } from "../components/withSearch"
import SEO from "../components/seo"
import { SearchOrder, useSearchParameters } from "../useSearchParameters"

const hiddenFilters: HidableFilters[] = ["style"]

interface PageComponentProps extends WithSearchWrappedProps {
  pageContext: { style: string }
}

const useStyles = makeStyles(() => ({
  orderByFormControl: {
    minWidth: 120,
  },
}))

const PageComponent: React.FC<PageComponentProps> = ({
  data,
  hasMore,
  histogramResults,
  isLoading,
  loadMore,
  pageContext,
}) => {
  const { style } = pageContext
  const [mobileTagFilterOpen, setMobileTagFilterOpen] = React.useState(false)
  const [{ orderby }, setSearchParameter] = useSearchParameters()
  const classes = useStyles()

  const handleCloseMobileTagFilter = () => setMobileTagFilterOpen(false)
  const handleOpenMobileTagFilter = () => setMobileTagFilterOpen(true)
  const handleSortSelected = (event: any) => {
    setSearchParameter("orderby", event.target.value)
  }

  const productItems =
    data?.map(product => (
      <ProductListItemMemo key={product.id} product={product} />
    )) ?? []

  return (
    <Box display="flex">
      <SEO
        title={`${style} Whisky - find the best prices`}
        description={`Compare prices for ${style} Whisky on The Liquor Project. Save money and compare 1000s of whiskies!`}
      />
      <TagFilter
        mobileTagFilterOpen={mobileTagFilterOpen}
        handleCloseMobileTagFilter={handleCloseMobileTagFilter}
        queryHistogramResult={histogramResults}
        hiddenFilters={hiddenFilters}
      />
      <Layout showHeader>
        <Box
          mt={5}
          mb={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5" component="h1">
              {style} Whisky
            </Typography>
          </Box>

          <Box marginLeft="auto" mr={2} display={{ xs: "none", lg: "flex" }}>
            <FormControl>
              <InputLabel id="order-by-select-helper-label">Order</InputLabel>
              <Select
                className={classes.orderByFormControl}
                labelId="order-by-select-helper-label"
                id="order-by-select"
                value={orderby ?? SearchOrder.POPULARITY}
                onChange={handleSortSelected}
              >
                <MenuItem value={SearchOrder.POPULARITY}>Popularity</MenuItem>
                <MenuItem value={SearchOrder.TITLE}>Name</MenuItem>
                <MenuItem value={SearchOrder.DISCOUNT}>Discount</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box height={2} mt={1}>
          {isLoading && <LinearProgress />}
        </Box>
        <Box mt={1} mb={2}>
          <Divider />
        </Box>
        <Box
          display={{ xs: "flex", lg: "none" }}
          marginY={1}
          flexDirection="row"
        >
          <Button
            onClick={handleOpenMobileTagFilter}
            variant="outlined"
            color="secondary"
          >
            Filter
          </Button>
          <Box marginLeft="auto">
            <FormControl>
              <InputLabel id="order-by-select-helper-label">Order</InputLabel>
              <Select
                className={classes.orderByFormControl}
                labelId="order-by-select-helper-label"
                id="order-by-select"
                value={orderby ?? SearchOrder.POPULARITY}
                onChange={handleSortSelected}
              >
                <MenuItem value={SearchOrder.POPULARITY}>Popularity</MenuItem>
                <MenuItem value={SearchOrder.TITLE}>Name</MenuItem>
                <MenuItem value={SearchOrder.DISCOUNT}>Discount</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box mt={2}>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} loader={loader}>
            <Grid key="grid-key" container spacing={1} direction="row">
              {productItems}
            </Grid>
          </InfiniteScroll>
          {!isLoading && productItems.length === 0 && (
            <Box>
              <Typography>{`No ${style} Whisky matches your filters`}</Typography>
            </Box>
          )}
        </Box>
      </Layout>
    </Box>
  )
}

interface StylePageProps {
  pageContext: any
  data: any
}
const StylePage: React.FC<StylePageProps> = props => {
  const { pageContext, data } = props
  const { style } = pageContext
  const histogram = data.hasura.query_histogram
  const products = data.hasura.query_and_order_products

  const WrappedPageComponent = React.useCallback(
    withSearch(PageComponent, {
      staticSearchParams: { style: [style] },
      primedData: {
        primedHistogram: histogram,
        primedProducts: products,
      },
    }),
    [style]
  )

  return <WrappedPageComponent pageContext={pageContext} />
}

const loader = (
  <Box
    key="loader-key"
    mt={8}
    display="flex"
    alignContent="center"
    justifyContent="center"
  >
    <CircularProgress></CircularProgress>
  </Box>
)

const ProductListItem: React.FC<{ product: ProductItemCardFragment }> = ({
  product,
}) => (
  <Grid md={4} xs={12} item>
    <Box display="flex" alignItems="center" justifyContent="center">
      <ProductItemCard product={product} />
    </Box>
  </Grid>
)

const ProductListItemMemo = React.memo(
  ProductListItem,
  (oldProps, newProps) => oldProps.product.id === newProps.product.id
)

export const query = graphql`
  query GetProductsWithType($styleQuery: Hasura__text!) {
    hasura {
      query_histogram(args: { style: $styleQuery }) {
        ...QueryHistogramResultFragment
      }
      query_and_order_products(
        args: { style: $styleQuery, orderby: "popularity" }
        limit: 100
      ) {
        ...ProductItemCardFragment
      }
    }
  }
`

export default StylePage
